import { ChangeDetectionStrategy, Component } from '@angular/core';
import { KioskService } from '../../kiosk.service';

@Component({
  selector: 'wlx-shop',
  templateUrl: './shop.component.html',
  styleUrls: ['./shop.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ShopComponent {
  constructor(public kioskService: KioskService) {}
}
